import React from 'react';
import SEO from '../components/seo';
import AlbumPromo from '../components/albumPromo';
import LayoutFull from '../components/layoutFull';
import VideoEmbed from '../embeds/videoEmbed';
import Logo from '../components/logo';
import GatsbyImage from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import SocialLinks from '../components/socialLinks';
import '../components/layout.css';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "abstractroom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div>
      <main
        style={{
          position: 'relative',
          zIndex: 2,
          padding: '2rem 6vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <section
          style={{
            // backgroundColor: 'rgba(0,0,0,0.4)',
            // padding: '3rem',
            width: '37rem',
            maxWidth: '100%',
            display: 'block',
            minHeight: '60vh',
          }}
        >
          <Logo style={{ fill: '#fff' }} />
          <div style={{ marginTop: '4rem' }}>
            <Link
              activeStyle={{
                backgroundColor: '#222',
              }}
              style={{
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                backgroundColor: '#000',
                textAlign: 'center',
                padding: '2rem',
                borderRadius: '1rem',
                fontSize: '1.5rem',
                marginBottom: '2rem',
                // fontWeight: 'bold',
                letterSpacing: '4px',
                textTransform: 'uppercase',
              }}
              to="/home"
            >
              Singer-Songwriter{' '}
              <span style={{ opacity: 0.5, paddingLeft: '1rem' }}>❯</span>
            </Link>
            <Link
              activeStyle={{
                backgroundColor: '#222',
              }}
              style={{
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                backgroundColor: '#000',
                textAlign: 'center',
                padding: '2rem',
                borderRadius: '1rem',
                fontSize: '1.5rem',
                marginBottom: '2rem',
                // fontWeight: 'bold',
                letterSpacing: '4px',
                textTransform: 'uppercase',
              }}
              to="/composition"
            >
              Composer{' '}
              <span style={{ opacity: 0.5, paddingLeft: '1rem' }}>❯</span>
            </Link>
          </div>
        </section>
        <footer
          style={{
            padding: '3rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '37rem',
            maxWidth: '100%',
            backgroundColor: 'rgba(0,0,0,0.4)',
            borderRadius: '6rem',
          }}
        >
          <SocialLinks instagram youtube spotify bandcamp />
        </footer>
      </main>
      <GatsbyImage
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </div>
  );
};

export default IndexPage;
