import React from 'react';

import {
  FaInstagram,
  FaTwitter,
  FaTwitch,
  FaYoutube,
  FaSpotify,
  FaBandcamp,
  FaFacebook,
  FaDiscord,
} from 'react-icons/fa';

function SocialLinks(props: {
  instagram?: boolean;
  twitter?: boolean;
  twitch?: boolean;
  youtube?: boolean;
  spotify?: boolean;
  bandcamp?: boolean;
  facebook?: boolean;
  discord?: boolean;
}) {
  return (
    <>
      {Boolean(props.bandcamp) && (
        <a
          target="_blank"
          rel="noopener"
          href="https://nicolette.bandcamp.com/"
          title="Buy my album on Bandcamp"
        >
          <FaBandcamp color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.instagram) && (
        <a
          target="_blank"
          rel="noopener"
          href="https://www.instagram.com/nicolettemacleodmusic/"
          title="I post photos and litle videos on Instagram"
        >
          <FaInstagram color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.twitter) && (
        <a
          target="_blank"
          rel="noopener"
          href="https://twitter.com/nicolettemmusic"
          title="I'm @nicolettemmusic on Twitter"
        >
          <FaTwitter color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.youtube) && (
        <a
          target="_blank"
          rel="noopener"
          href="https://www.youtube.com/channel/UCG85Tjg1eXcw7eHCiIUqg8A"
          title="You can subscribe to my Youtube channel"
        >
          <FaYoutube color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.spotify) && (
        <a
          target="_blank"
          rel="noopener"
          href="https://open.spotify.com/artist/3BmwWP69tqDEQcQ6TtgHm7"
          title="My album is on Spotify"
        >
          <FaSpotify color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.twitch) && (
        <a
          target="_blank"
          rel="noopener"
          href="https://www.twitch.tv/nicolettemusic"
          title="Come join me on Twitch"
        >
          <FaTwitch color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.facebook) && (
        <a
          href="https://www.facebook.com/nicolettemacleodmusic/"
          target="_blank"
          rel="noopener"
          title="Facebook"
        >
          <FaFacebook color="#fff" size={50} />
        </a>
      )}
      {Boolean(props.discord) && (
        <a
          href="https://discord.gg/tCZyzFp"
          target="_blank"
          rel="noopener"
          title="Join my Discord"
        >
          <FaDiscord color="#fff" size={50} />
        </a>
      )}
    </>
  );
}

export default SocialLinks;
